import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'
import Button from 'components/Button/Button'
import Textbox from 'components/Textbox/Textbox'
import TextboxError from 'components/Textbox/TextboxError'
import Dropdown from 'components/Dropdown/Dropdown'
import Switch from 'components/Switch/Switch'

import { createPricing, listPriceLists } from 'utils/api/pricing'

import './PricingListPage.css'
import './CreatePriceListPanel.css'
import { listPartners } from 'utils/api/partners'

const CreatePriceListPanel = ({ fetchPricing }) => {
  const [name, setName] = useState('')
  const [step, setStep] = useState(1)
  const [error, setError] = useState('')
  const [startingItems, setStartingItems] = useState('empty')
  const [syncNewProducts, setSyncNewProducts] = useState(false)
  const [newProductMarginType, setNewProductMarginType] = useState('percentage')
  const [newProductMarginValue, setNewProductMarginValue] = useState('0')
  const [partners, setPartners] = useState([])
  const [allPartners, setAllPartners] = useState([])
  const [creating, setCreating] = useState(false)
  const [initialProductsMarginType, setInitialProductsMarginType] =
    useState('percentage')
  const [initialProductsMarginValue, setInitialProductsMarginValue] =
    useState('0')

  useEffect(() => {
    listPartners({
      page: 1,
      limit: 250,
    }).then((response) => {
      if (response.success) {
        setAllPartners(response.data.partners)
      }
    })
  }, [])

  const onComplete = (e) => {
    e.preventDefault()

    setError('')
    setCreating(true)
    createPricing({
      name,
      startingItems,
      syncNewProducts,
      newProductMarginType,
      newProductMarginValue,
      partners,
      initialProductsMarginType,
      initialProductsMarginValue,
    }).then((response) => {
      setCreating(false)
      if (response.success) {
        setStep(1)
        setName('')
        setStartingItems('empty')
        setSyncNewProducts(false)
        setNewProductMarginType('percentage')
        setNewProductMarginValue('0')
        setPartners([])
        fetchPricing()
      } else {
        setError(response.data.message)
      }
    })
  }

  const onNext = (e) => {
    e.preventDefault()
    setError('')

    if (step == 1) {
      if (name == '') {
        setError('Name is required')
        return
      }
      setStep(step + 1)
    } else if (step == 2) {
      setStep(step + 1)
    } else if (step == 3) {
      if (allPartners.length == 0) {
        setStep(step + 2)
      } else {
        setStep(step + 1)
      }
    } else if (step == 4) {
      setStep(step + 1)
    }
  }

  return (
    <div className="card">
      <div className="card-body">
        <div>
          <h2 className="create-price-list-title">Create Price List</h2>
          <p className="create-price-list-steps">Step {step} of 5</p>
        </div>
        <div>
          {error && error !== '' && <TextboxError>{error}</TextboxError>}
          {step == 1 ? (
            <Textbox
              label="Name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          ) : step == 2 ? (
            <div>
              <Dropdown
                label="Start my price list with"
                value={startingItems}
                onChange={(e) => setStartingItems(e.target.value)}
              >
                <option value="empty">Empty</option>
                <option value="all">All Products & Variants</option>
              </Dropdown>
              <p className="create-price-list-help-text">
                You can edit the values on the list later with both the UI, or
                CSV.
              </p>
              {startingItems == 'all' && (
                <>
                  <Dropdown
                    label="Initial Products Margin Type"
                    value={initialProductsMarginType}
                    onChange={(e) =>
                      setInitialProductsMarginType(e.target.value)
                    }
                  >
                    <option value="percentage">Percentage</option>
                    <option value="fixed">Fixed</option>
                  </Dropdown>
                  <Textbox
                    label="Initial Products Margin"
                    placeholder="Margin"
                    value={initialProductsMarginValue}
                    onChange={(e) =>
                      setInitialProductsMarginValue(e.target.value)
                    }
                    helpText={
                      initialProductsMarginType == 'fixed'
                        ? 'This dollar amount is subtracted from the retail price.'
                        : 'This percentage is subtracted from the retail price.'
                    }
                  />
                </>
              )}
            </div>
          ) : step == 3 ? (
            <div>
              <Switch
                label="Automatically sync new products to this price list"
                value={syncNewProducts}
                onChange={(e) => setSyncNewProducts(!syncNewProducts)}
              />
              <br />
              <br />
              {syncNewProducts && (
                <>
                  <Dropdown
                    label="Margin Type"
                    value={newProductMarginType}
                    onChange={(e) => setNewProductMarginType(e.target.value)}
                  >
                    <option value="percentage">Percentage</option>
                    <option value="fixed">Fixed</option>
                  </Dropdown>
                  <Textbox
                    label="Margin"
                    placeholder="Margin"
                    value={newProductMarginValue}
                    onChange={(e) => setNewProductMarginValue(e.target.value)}
                    helpText={
                      newProductMarginType == 'fixed'
                        ? 'This dollar amount is subtracted from the retail price.'
                        : 'This percentage is subtracted from the retail price.'
                    }
                  />
                </>
              )}
            </div>
          ) : step == 4 ? (
            <div>
              <p>Assign price list to partners</p>
              {allPartners.map((partner) => (
                <div>
                  <input
                    type="checkbox"
                    value={partner.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setPartners([...partners, partner.id])
                      } else {
                        setPartners(partners.filter((p) => p !== partner.id))
                      }
                    }}
                  />
                  <label>{partner.name}</label>
                </div>
              ))}
            </div>
          ) : step == 5 ? (
            <div className="create-price-list-review">
              <p>Name: {name}</p>
              <p>Starting Items: {startingItems}</p>
              <p>Sync New Products: {syncNewProducts ? 'Yes' : 'No'}</p>
              {syncNewProducts && (
                <>
                  <p>Margin Type: {newProductMarginType}</p>
                  <p>Margin Value: {newProductMarginValue}</p>
                </>
              )}
              <p>
                Partners:{' '}
                {partners.length > 0
                  ? partners.join(', ')
                  : '(Not assigning to any partners)'}
              </p>
            </div>
          ) : null}
        </div>
        <div className="create-price-list-next-wrapper">
          {step == 5 ? (
            <Button onClick={onComplete} loading={creating}>
              Finish
            </Button>
          ) : (
            <Button onClick={onNext}>Next</Button>
          )}
        </div>
      </div>
    </div>
  )
}

const displayMargin = (minMargin, maxMargin) => {
  if (minMargin == -1 && maxMargin == -1) {
    return 'N/A'
  }
  if (minMargin == maxMargin) {
    return `${minMargin}%`
  }
  if (minMargin == -1 && maxMargin != -1) {
    return `N/A - ${maxMargin}%`
  }
  if (minMargin != -1 && maxMargin == -1) {
    return `${minMargin}% - N/A`
  }
  return `${minMargin}% - ${maxMargin}%`
}

const PriceListItem = ({ priceList }) => {
  let navigate = useNavigate()

  const onNavigateToPricingPage = (e) => {
    e.preventDefault()
    navigate(`/pricing/${priceList.id}`)
  }
  return (
    <tr>
      <td onClick={onNavigateToPricingPage} style={{ cursor: 'pointer' }}>
        {priceList.name}
      </td>
      <td>
        {priceList.partners.length > 0 && (
          <>
            {Object.keys(priceList.partners).map((partnerId) => (
              <Link className="partners-link" to={`/partners/${partnerId}`}>
                {priceList.partners[partnerId]}
              </Link>
            ))}
          </>
        )}
      </td>
      <td>{priceList.variants < 0 ? 'N/A' : priceList.variants}</td>
      <td>{displayMargin(priceList.minMargin, priceList.maxMargin)}</td>
      <td>
        <button className="btn btn-light" onClick={onNavigateToPricingPage}>
          View
        </button>
      </td>
    </tr>
  )
}

const PricingListPage = () => {
  const [loading, setLoading] = useState(true)
  const [priceLists, setPriceLists] = useState([])
  const [page, setPage] = useState(1)

  useEffect(() => {
    fetchPricing()
  }, [])

  const fetchPricing = () => {
    listPriceLists({
      page,
      limit: 25,
    }).then((response) => {
      setLoading(false)
      if (response.success) {
        setPriceLists(response.data.priceLists || [])
      }
    })
  }

  if (loading) {
    return (
      <Page>
        <PageTitleRow>
          <div className="col-12">
            <h1>Pricing</h1>
          </div>
        </PageTitleRow>
        <div className="row">
          <div className="col-12">
            <p>Loading...</p>
          </div>
        </div>
      </Page>
    )
  }

  return (
    <Page>
      <PageTitleRow>
        <div className="col-12">
          <h1>Pricing</h1>
        </div>
      </PageTitleRow>
      <div className="row">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <table className="table w-100 dataTable dtr-inline">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Partners</th>
                    <th>Variants</th>
                    <th>Margin</th>
                    <th></th>
                  </tr>
                </thead>
                {priceLists.length == 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan="4">No price lists found</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {priceLists.map((priceList) => (
                      <PriceListItem priceList={priceList} />
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        <div className="col-4">
          <CreatePriceListPanel fetchPricing={fetchPricing} />
        </div>
      </div>
    </Page>
  )
}

export default PricingListPage
