import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Page from 'components/Page/Page'
import Panel from 'components/Panel/Panel'

import { getCurrentOrganization, getDashboard } from 'utils/api/organizations'
import { getCompanyId } from 'utils/cookies'
import { getDateFromNow, diplayYYYYMMDD } from 'utils/date'
import { prettifyNumber } from 'utils/numbers'

import './DashboardPage.css'
import {
  listInstalls,
  listPendingAssociations,
  assignPendingAssociation,
} from 'utils/api/installs'

import Button from 'components/Button/Button'
import Dropdown from 'components/Dropdown/Dropdown'

import './DashboardPage.css'
import './PendingAssociationsPanel.css'
import { getMe } from 'utils/api/users'
import { Link } from 'react-router-dom'

import LineGraph from './LineGraph'
import LineGraphSelector from './LineGraphSelector'

const WelcomeMessage = ({ displayName, loading }) => {
  if (loading) {
    return (
      <div className="dashboard-page-welcome-row">
        <div className="dashboard-page-title-loading"></div>
        <div className="dashboard-page-welcome-subtitle-text-loading"></div>
      </div>
    )
  }
  let welcomeMessage = 'Good day'
  const currentHour = new Date().getHours()
  if (currentHour >= 0 && currentHour < 12 && displayName !== '') {
    welcomeMessage = `Good morning, ${displayName}`
  } else if (currentHour >= 12 && currentHour < 18 && displayName !== '') {
    welcomeMessage = `Good afternoon, ${displayName}`
  } else if (displayName !== '') {
    welcomeMessage = `Good evening, ${displayName}`
  } else if (currentHour >= 0 && currentHour < 12) {
    welcomeMessage = 'Good morning'
  } else if (currentHour >= 12 && currentHour < 18) {
    welcomeMessage = 'Good afternoon'
  } else {
    welcomeMessage = 'Good evening'
  }
  return (
    <div className="dashboard-page-welcome-row">
      <h1 className="dashboard-page-title">{welcomeMessage}</h1>
      <p className="dashboard-page-welcome-subtitle-text">
        Welcome back to your portal.
      </p>
    </div>
  )
}

const getData = (dailySnapshots, graphTab) => {
  if (!dailySnapshots) {
    return []
  }
  return dailySnapshots.map((snapshot) => {
    let y = snapshot[graphTab]
    if (graphTab === 'gmvTotal') {
      // Convert from cents to dollars
      y = y / 100
    }
    return {
      x: snapshot.timestamp.split('T')[0],
      y: y,
    }
  })
}

const DashboardCard = ({ label, metric, value }) => {
  return (
    <div className="col-md-3">
      <div className="card">
        <div className="card-body">
          <p className="dashboard-card-value">
            {metric === '$' && '$'}
            {prettifyNumber(value || 0)}
            {metric && metric !== '$' && (
              <span className="dashboard-card-metric"> {metric}</span>
            )}
          </p>

          <p className="dashboard-card-label">{label}</p>
        </div>
      </div>
    </div>
  )
}

const DashboardPage = () => {
  let navigate = useNavigate()
  const companyId = getCompanyId()
  const [loadingCompany, setLoadingCompany] = useState(true)
  const [currentCompany, setCurrentCompany] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [loadingMe, setLoadingMe] = useState(true)
  const [installs, setInstalls] = useState([])
  const [loadingInstalls, setLoadingInstalls] = useState(true)
  const [pendingAssociations, setPendingAssociations] = useState([])
  const [loadingPendingAssociations, setLoadingPendingAssociations] =
    useState(true)
  const [companies, setCompanies] = useState([])

  // Get 7 days ago
  const [start, setStart] = useState(
    diplayYYYYMMDD(getDateFromNow(-8).toISOString())
  )
  const [end, setEnd] = useState(
    diplayYYYYMMDD(getDateFromNow(-1).toISOString())
  )
  const [graphTab, setGraphTab] = useState('gmvTotal')
  const [loadingSnapshots, setLoadingSnapshots] = useState(true)
  const [loadingDashboard, setLoadingDashboard] = useState(true)
  const [dailySnapshots, setDailySnapshots] = useState([])
  const [openOrders, setOpenOrders] = useState(0)
  const [gmvTotal, setGmvTotal] = useState(0)
  const [averageShipTime, setAverageShipTime] = useState(0)
  const [products, setProducts] = useState(0)

  const fetchSnapshots = () => {
    if (!companyId || companyId === '') {
      // This is required, otherwise it will try and fetch current company
      // with a blank companyId. That will return a 403 and the cookies
      // will be cleared.
      return
    }

    setLoadingSnapshots(true)
    getDashboard({ start, end }).then((response) => {
      if (response.success) {
        setDailySnapshots(response.data.snapshots)
        setOpenOrders(response.data.openOrders || 0)
        setGmvTotal(response.data.gmvTotal || 0)
        setAverageShipTime(response.data.averageShipTime || 0)
        setProducts(response.data.products || 0)
      }
      setLoadingSnapshots(false)
      setLoadingDashboard(false)
    })
  }

  useEffect(() => {
    fetchSnapshots()
  }, [start, end])

  useEffect(() => {
    if (!companyId || companyId === '') {
      // This is required, otherwise it will try and fetch current company
      // with a blank companyId. That will return a 403 and the cookies
      // will be cleared.
      navigate('/create-company')
      return
    }
    getCurrentOrganization(companyId).then((response) => {
      setLoadingCompany(false)
      if (response.success) {
        setCurrentCompany(response.data.company)
      }
    })
    getMe().then((response) => {
      setLoadingMe(false)
      if (response.success) {
        setCompanies(response.data.companiesWithAccess)
        setCurrentUser(response.data.user)
      }
    })

    fetchSnapshots()
  }, [companyId])

  if (loadingCompany || loadingMe) {
    return (
      <Page>
        <div className="row dashboard-top-row dashboard-row">
          <div className="col-md-1"></div>
          <div className="col-md-7">
            <WelcomeMessage
              displayName={(currentUser && currentUser.displayName) || ''}
              loading={true}
            />
          </div>
        </div>
      </Page>
    )
  }

  return (
    <Page>
      <div className="row dashboard-top-row dashboard-row">
        <div className="col-md-12">
          <WelcomeMessage
            displayName={(currentUser && currentUser.displayName) || ''}
          />
          {loadingDashboard ? (
            <div></div>
          ) : (
            <div className="card">
              <div className="card-body">
                <LineGraphSelector
                  setGraphTab={setGraphTab}
                  graphTab={graphTab}
                  start={start}
                  setStart={setStart}
                  end={end}
                  setEnd={setEnd}
                  loadingSnapshots={loadingSnapshots}
                />
                <LineGraph data={getData(dailySnapshots, graphTab)} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <DashboardCard label="Open Orders" metric="" value={openOrders} />
        <DashboardCard
          label="GMV this month"
          metric="$"
          value={gmvTotal / 100}
        />
        <DashboardCard
          label="Average Ship Time"
          metric="days"
          value={averageShipTime}
        />
        <DashboardCard label="Products" metric="" value={products} />
      </div>
    </Page>
  )
}

export default DashboardPage
