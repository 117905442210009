import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import Panel from 'components/Panel/Panel'
import Logo from 'components/Logo/Logo'
import LogoIcon from 'components/Logo/LogoIcon'
import Button from 'components/Button/Button'
import Textbox from 'components/Textbox/Textbox'
import CheckoutForm from 'containers/Settings/Billing/CheckoutForm'

import { createOrganization } from 'utils/api/organizations'
import { getMe } from 'utils/api/users'
import { listPartnerInvites } from 'utils/api/partners'

import { isSignedIn, setCompanyId, getAuthToken, getEmail } from 'utils/cookies'
import { getStripeKey } from 'utils/constants'

import SinglePendingAssociation from 'containers/Dashboard/SinglePendingAssociation'

import SinglePartnerInvite from './SinglePartnerInvite'

import 'containers/SignIn/SignInPage.css'
import './CreateCompanyPage.css'

const promise = loadStripe(getStripeKey())

const CompletedIndicator = () => {
  return (
    <div className="onboarding-completed-indicator">
      <div className="onboarding-completed-icon-wrapper">
        <i className="bi bi-check onboarding-completed-icon"></i>
      </div>
      <p>Completed</p>
    </div>
  )
}

const isTestEmail = (user) => {
  if (!user) {
    return false
  }
  let email = user.email || ''
  if (email === '') {
    return false
  }

  email = email.toLowerCase()
  return email.includes('testshopify') || email.includes('@shopify.com')
}

const CreateCompanyPage = () => {
  let navigate = useNavigate()
  const [creatingOrg, setCreatingOrg] = useState(false)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [redirect, setRedirect] = useState('')
  const [loadingUser, setLoadingUser] = useState(true)
  const [user, setUser] = useState({})
  const [step2Status, setStep2Status] = useState('')
  const [step1Status, setStep1Status] = useState('show')
  const [step3Status, setStep3Status] = useState('')
  const [loadingPartnerInvites, setLoadingPartnerInvites] = useState(true)
  const [partnerInvites, setPartnerInvites] = useState([])
  const [loadingMe, setLoadingMe] = useState(true)
  const [companies, setCompanies] = useState([])
  const [currentCompany, setCurrentCompany] = useState({})

  // Get shop query parameter
  const queryParams = new URLSearchParams(window.location.search)
  const shop = queryParams.get('shop')
  const shopifyPass = queryParams.get('shopify_pass')

  useEffect(() => {
    if (!isSignedIn()) {
      navigate('/signin?reason=signed-out')
    }
  }, [])

  const fetchCompanyList = () => {
    console.log('fetchCompanyList')
    setLoadingMe(true)
    getMe().then((response) => {
      console.log('fetchCompanyList :: response', response)
      setLoadingUser(false)
      if (response.success) {
        setUser(response.data.user)
        setCompanies(response.data.companiesWithAccess)
      }
      setLoadingMe(false)
    })
  }

  const onStep3Completed = () => {
    setStep3Status('completed')
    setTimeout(() => {
      // If installId is in the query params, redirect to the install page
      const urlParams = new URLSearchParams(window.location.search)
      const redirectURL = urlParams.get('redirect')
      if (redirectURL && redirectURL !== '') {
        setRedirect(redirectURL)
      } else {
        setRedirect('/onboarding/')
      }
    }, 5000)
  }

  useEffect(() => {
    fetchCompanyList()
  }, [])

  const onSubmit = (e) => {
    e.preventDefault()

    if (name.length == 0) {
      setNameError('Name is required')
      return
    }

    setCreatingOrg(true)
    createOrganization({
      name,
      useShopifyBilling: shop && shop !== '' ? true : false,
      shopifyPass: shopifyPass || '', 
    }).then((response) => {
      console.log('response', response)
      if (response.success) {
        setCompanyId(response.data.organization.id)
        setCurrentCompany(response.data.organization)
        // if ((shop && shop !== '') || (response.data.organization && response.data.organization.hideBilling)) {
          // Skip step 2
          onStep3Completed()
        // } else {
        //   setStep2Status('show') 
        // }
        setStep1Status('completed')
        fetchCompanyList()
      }
      setCreatingOrg(false)
    })
  }

  const setCardSaved = (e) => {
    setStep2Status('completed')
    onStep3Completed()
  }

  if (redirect !== '') {
    console.log('redirect', redirect)
    navigate(redirect)
  }

  const now = new Date()
  const freeTrail = new Date(now.setMonth(now.getMonth() + 1))
  // const hideBillingStep = (shop && shop !== '') || isTestEmail(user)
  const hideBillingStep = true; // for now everyone
  return (
    <div className="container-fluid">
      <div className="row onboarding-row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <LogoIcon style={{ textAlign: 'left', paddingBottom: 40 }} />
          <h2 className="create-company-title">
            Hello{user && user.firstName && `, ${user.firstName}`}
          </h2>
          <p>Let's setup a new organization for you and your team.</p>

          <div className="onboarding-panel">
            <h2 className="create-company-instructions-title">
              1. Set up your Organization
            </h2>
            {step1Status == 'show' ? (
              <>
                <p className="create-company-instructions-text">
                  Enter the name of your organization
                </p>
                <form onSubmit={onSubmit}>
                  <div>
                    <Textbox
                      value={name}
                      placeholder="Name"
                      onChange={(e) => setName(e.target.value)}
                      error={nameError}
                    />
                  </div>
                  <div className="create-company-onboard-btn-wrapper">
                    <Button loading={creatingOrg}>Create</Button>
                  </div>
                </form>
              </>
            ) : step1Status === 'completed' ? (
              <CompletedIndicator />
            ) : null}
          </div>
          {
            (hideBillingStep) ? (
              null
            ) : (
            <div className="onboarding-panel">
              <h2 className="create-company-instructions-title">
                2. Set up your Billing
              </h2>
              {step2Status === 'show' ? (
                <>
                  <p className="create-company-instructions-text">
                    Enter your billing information
                  </p>
                  <Elements stripe={promise}>
                    <CheckoutForm setCardSaved={setCardSaved} />
                  </Elements>
                  <p>
                    You will have a free trail till {freeTrail.toDateString()}.
                  </p>
                </>
              ) : step2Status === 'completed' ? (
                <CompletedIndicator />
              ) : null}
            </div>
            )
          }
          
          <div className="onboarding-panel">
            <h2 className="create-company-instructions-title">{hideBillingStep ? '2' : '3'}. Ready</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateCompanyPage
