import React from 'react'

import Page from 'components/Page/Page'
import SubMenu from 'components/SubMenu/SubMenu'

import './SettingsPage.css'

/*
<SubMenu
              items={{
                '/settings/': 'Profile',
                // '/settings/company': 'Company',
                '/settings/billing': 'Billing',
                '/settings/api': 'API',
                '/settings/create-company': 'Create Company',
              }}
            />
*/

const SettingsPage = ({ children, loading, developerMode }) => {
  return (
    <Page>
      <div className="container-fluid">
      <h1 class="h3 mb-3">Settings</h1>
      <div class="row">
						<div class="col-md-3 col-xl-2">

            <div class="card">
								<div class="card-header">
									<h5 class="card-title mb-0">Profile Settings</h5>
								</div>

								<div class="list-group list-group-flush" role="tablist">
									<a class={`list-group-item list-group-item-action ${window.location.pathname == '/settings/' ? 'active' : ''}`} href="/settings/" role="tab">
                    Account
                  </a>
                  <a class={`list-group-item list-group-item-action ${window.location.pathname == '/settings/company' ? 'active' : ''}`} href="/settings/company" role="tab">
                    Company
                  </a>
                  <a class={`list-group-item list-group-item-action ${window.location.pathname == '/settings/products' ? 'active' : ''}`} href="/settings/products" role="tab">
                    Products
                  </a>
									<a class={`list-group-item list-group-item-action ${window.location.pathname == '/settings/billing' ? 'active' : ''}`} href="/settings/billing" role="tab">
                    Billing
                  </a>
									<a class={`list-group-item list-group-item-action ${window.location.pathname == '/settings/invoicing' ? 'active' : ''}`} href="/settings/invoicing" role="tab">
                    Invoicing
                  </a>
                  <a class={`list-group-item list-group-item-action ${window.location.pathname == '/settings/white-labelling' ? 'active' : ''}`} href="/settings/white-labelling" role="tab">
                    White Labelling
                  </a>
                  {
                    (developerMode) && (
                      <>
                        <a class={`list-group-item list-group-item-action ${window.location.pathname == '/settings/api' ? 'active' : ''}`} href="/settings/api" role="tab">
                          API
                        </a>
                        <a class={`list-group-item list-group-item-action ${window.location.pathname == '/settings/developer' ? 'active' : ''}`} href="/settings/developer" role="tab">
                          Developer
                        </a>
                      </>
                    )
                  }
									
                  <a class={`list-group-item list-group-item-action ${window.location.pathname == '/settings/create-company' ? 'active' : ''}`} href="/settings/create-company" role="tab">
                    New Company
                  </a>
                  
									
								</div>
							</div>
						</div>

						<div class="col-md-9 col-xl-10">
							<div class="tab-content">
								<div class="tab-pane fade show active" id="account" role="tabpanel">

									<div class="card">
										<div class="card-body">
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </Page>
  )
}

export default SettingsPage
