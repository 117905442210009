import { useState, useEffect, useRef } from 'react'

import { CreateDesignPage, EditDesignPage } from './EditDesign'
import {
  getPosterStudioDetails,
  listDesigns,
  updatePosterStudioDetails,
  sync,
} from './api'

import './PosterStudioAppPage.css'
import { ChevronLeft, ChevronRight, CircleCheck } from 'lucide-react'
import { displayDollarAmount, formatDollar } from 'utils/money'

const DesignsRow = ({
  design,
  setEditDesignId,
  setPage,
  setSelectedDesigns,
  selectedDesigns,
}) => {
  const onEditClick = (e) => {
    e.preventDefault()
    setEditDesignId(design.id)
    setPage('edit-design')
  }
  return (
    <tr key={design.id}>
      <td>
        <input type="checkbox" className="form-check-input" />
      </td>
      <td>
        {/*base 64 using design.mainImage*/}
        <img
          src={
            design.mainImage
              ? `data:${design.mimeType};base64,${design.mainImage}`
              : 'https://via.placeholder.com/150'
          }
          alt=""
          className="design-thumbnail"
        />
        {design.name || design.title || '(Unnamed)'}
      </td>
      <td>
        {design.status === 'active' ? (
          <span className="badge rounded-pill bg-success">Active</span>
        ) : design.status === 'draft' ? (
          <span className="badge rounded-pill bg-warning">Draft</span>
        ) : design.status === 'archived' ? (
          <span className="badge rounded-pill bg-danger">Archived</span>
        ) : (
          <span className="badge rounded-pill bg-secondary">
            {design.status}
          </span>
        )}
      </td>
      <td>
        {design.productId && design.productId !== '' && (
          <a
            href={`/products/${design.productId}`}
            className="btn btn-outline-primary btn-sm"
          >
            View Product
          </a>
        )}
      </td>
      <td>
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={onEditClick}
        >
          Edit
        </button>
      </td>
    </tr>
  )
}

const DesignLoadingRow = () => {
  return (
    <tr>
      <td>
        <input type="checkbox" className="form-check-input" />
      </td>
      <td>
        <div className="design-loading-cell"></div>
      </td>
      <td>
        <div className="design-loading-cell"></div>
      </td>
      <td>
        <div className="design-loading-cell"></div>
      </td>
      <td>
        <div className="design-loading-cell"></div>
      </td>
    </tr>
  )
}

const DesignsSection = ({
  viewToken,
  details,
  setEditDesignId,
  setPage,
  selectedDesigns,
  setSelectedDesigns,
}) => {
  const [designs, setDesigns] = useState([])
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState(1)
  const LIMIT = 10

  const onDecrementPage = (e) => {
    if (pagination == 1) {
      return
    }
    setPagination(pagination - 1)
  }

  const onIncrementPage = (e) => {
    setPagination(pagination + 1)
  }

  const fetchDesigns = () => {
    setLoading(true)
    listDesigns(viewToken, { page: pagination, limit: LIMIT }).then(
      (response) => {
        setLoading(false)
        if (response.success) {
          setDesigns(response.data.designs)
        } else {
          console.log(response.data.message)
        }
      }
    )
  }

  useEffect(() => {
    fetchDesigns()
  }, [])

  return (
    <div>
      <table className="table w-100 dataTable dtr-inline">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Status</th>
            <th>Link</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <>
              <DesignLoadingRow />
              <DesignLoadingRow />
              <DesignLoadingRow />
            </>
          ) : designs.length == 0 ? (
            <>
              <tr>
                <td colSpan="5">No designs found.</td>
              </tr>
            </>
          ) : (
            <>
              {(designs || []).map((design) => (
                <DesignsRow
                  key={design.id}
                  design={design}
                  setEditDesignId={setEditDesignId}
                  setPage={setPage}
                  selectedDesigns={selectedDesigns}
                  setSelectedDesigns={setSelectedDesigns}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
      <div className="designs-pagination-wrapper">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <button
                className={`page-link ${pagination == 1 ? 'disabled' : ''}`}
                onClick={onDecrementPage}
                disabled={pagination == 1}
              >
                <ChevronLeft />
              </button>
            </li>
            <li className="page-item">
              <a href="#" className="page-link">
                {pagination}
              </a>
            </li>
            <li className="page-item">
              <button
                className={`page-link ${
                  designs.length < LIMIT ? 'disabled' : ''
                }`}
                onClick={onIncrementPage}
                disabled={designs.length < LIMIT}
              >
                <ChevronRight />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

const AddVariantTemplate = ({
  viewToken,
  variantTemplates,
  setVariantTemplates,
  locations,
}) => {
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')
  const [title, setTitle] = useState('')
  const [price, setPrice] = useState('')
  const [currency, setCurrency] = useState('USD')
  const [skuSuffix, setSkuSuffix] = useState('')
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [length, setLength] = useState('')
  const [weight, setWeight] = useState('')
  const [notes, setNotes] = useState('')
  const [selectedLocations, setSelectedLocations] = useState(locations || [])

  const onSubmit = (e) => {
    e.preventDefault()

    setSaving(true)
    setError('')

    let formattedPrice = price
    if (formattedPrice.includes(',')) {
      formattedPrice = formattedPrice.replace(',', '')
    }
    if (!formattedPrice.includes('.')) {
      formattedPrice = formattedPrice + '.00'
    } else if (formattedPrice.split('.')[1].length < 2) {
      formattedPrice = formattedPrice + '0'
    }

    const priceInCents = parseFloat(price) * 100

    updatePosterStudioDetails(viewToken, {
      addVariantTemplate: {
        title,
        price: priceInCents,
        currency,
        skuSuffix,
        notes,
        width: parseFloat(width),
        height: parseFloat(height),
        length: parseFloat(length),
        weight: parseFloat(weight),
        locations: selectedLocations.map((loc) => loc.id),
      },
    }).then((response) => {
      setSaving(false)
      if (response.success) {
        console.log('Response', response)
        setVariantTemplates(response.data.install.variantTemplates)
        // setVariantTemplates([...variantTemplates, response.data])
        setTitle('')
        setPrice('')
        setCurrency('USD')
        setSkuSuffix('')
        setNotes('')
        setWidth('')
        setHeight('')
        setLength('')
        setWeight('')
      } else {
        setError(response.data.message)
      }
    })
  }

  return (
    <div style={{ padding: '10px 0px' }}>
      <form onSubmit={onSubmit}>
        {error && error !== '' && (
          <div className="alert alert-danger" role="alert">
            <div className="alert-message">{error}</div>
          </div>
        )}
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="No Frame"
          />
        </div>
        <div className="form-group">
          <label>Price</label>
          <input
            type="text"
            className="form-control"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="100.00"
          />
        </div>
        <div className="form-group">
          <label>Currency</label>
          <select
            className="form-control"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="GBP">GBP</option>
            <option value="CAD">CAD</option>
          </select>
        </div>
        <div className="form-group">
          <label>SKU Suffix</label>
          <input
            type="text"
            className="form-control"
            value={skuSuffix}
            onChange={(e) => setSkuSuffix(e.target.value)}
            placeholder="-no-frame"
          />
        </div>
        <div className="form-group">
          <label>Notes</label>
          <textarea
            className="form-control"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="No frame included."
          ></textarea>
        </div>
        <div>
          <div className="form-group">
            <label>Width (Inches)</label>
            <input
              type="text"
              className="form-control"
              value={width}
              onChange={(e) => setWidth(e.target.value)}
              placeholder="24"
            />
          </div>
          <div className="form-group">
            <label>Height (Inches)</label>
            <input
              type="text"
              className="form-control"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              placeholder="36"
            />
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>Length (Inches)</label>
            <input
              type="text"
              className="form-control"
              value={length}
              onChange={(e) => setLength(e.target.value)}
              placeholder="1"
            />
          </div>
          <div className="form-group">
            <label>Weight (lbs)</label>
            <input
              type="text"
              className="form-control"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              placeholder="1"
            />
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>Locations</label>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: 50 }}></th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {locations.map((location) => (
                <tr key={location.id}>
                  <td style={{ width: 50 }}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedLocations.includes(location.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedLocations([
                            ...selectedLocations,
                            location.id,
                          ])
                        } else {
                          setSelectedLocations(
                            selectedLocations.filter(
                              (loc) => loc !== location.id
                            )
                          )
                        }
                      }}
                    />
                  </td>
                  <td>{location.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <button className="btn btn-outline-primary">Add</button>
      </form>
    </div>
  )
}

const SettingsSection = ({ details, viewToken, locations }) => {
  const [brand, setBrand] = useState(details.brand || '')
  const [savingBrand, setSavingBrand] = useState(false)
  const [variantTemplates, setVariantTemplates] = useState(
    details.variantTemplates || []
  )
  const [showAddVariantTemplate, setShowAddVariantTemplate] = useState(false)

  const onSubmitBrand = (e) => {
    e.preventDefault()

    updatePosterStudioDetails(viewToken, { brand }).then((response) => {
      if (response.success) {
        // console.log('Response', response)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  return (
    <div className="section-inner-body">
      <form onSubmit={onSubmitBrand}>
        <div className="form-group">
          <label>Brand</label>
          <input
            type="text"
            className="form-control"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
            style={{ width: '300px' }}
            placeholder="Your Brand"
          />
        </div>
        {details.brand !== brand && (
          <button className="btn btn-outline-primary" disabled={savingBrand}>
            {savingBrand ? 'Saving...' : 'Save'}
          </button>
        )}
      </form>
      <br />
      <p className="variant-templates-title">
        <b>Variant Templates</b>
      </p>
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={(e) => setShowAddVariantTemplate(!showAddVariantTemplate)}
      >
        {showAddVariantTemplate ? 'Cancel' : 'Add Variant Template'}
      </button>
      {showAddVariantTemplate && (locations || []).length === 0 ? (
        <div className="alert alert-warning" role="alert">
          <div className="alert-message">
            You need to add locations before adding variant templates.
          </div>
        </div>
      ) : showAddVariantTemplate ? (
        <AddVariantTemplate
          viewToken={viewToken}
          variantTemplates={variantTemplates}
          setVariantTemplates={setVariantTemplates}
          locations={locations}
        />
      ) : (
        <div className="form-group">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Price</th>
                <th>SKU Suffix</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {variantTemplates.map((variant) => (
                <tr key={variant.id}>
                  <td>{variant.title}</td>
                  <td>
                    {displayDollarAmount(variant.price, variant.currency)}
                  </td>
                  <td>{variant.skuSuffix}</td>
                  <td>
                    <button className="btn btn-outline-danger btn-sm">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

const PosterStudioAppPage = () => {
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState(null)
  const pageFromParam = new URLSearchParams(window.location.search).get('page')
  const [page, setPage] = useState(pageFromParam || '')
  const [editDesignId, setEditDesignId] = useState(null)
  const [selectedDesigns, setSelectedDesigns] = useState([])
  const [syncing, setSyncing] = useState(false)
  const [locations, setLocations] = useState([])

  const viewToken = new URLSearchParams(window.location.search).get('viewToken')

  useEffect(() => {
    getPosterStudioDetails(viewToken, {}).then((response) => {
      if (response.success) {
        setDetails(response.data.install)
        setLoading(false)
        setLocations(response.data.locations || [])
      } else {
        window.alert(response.data.message)
      }
    })
  }, [])

  const onSyncNow = (e) => {
    if (loading) {
      return
    }
    if (syncing) {
      return
    }

    setSyncing(true)
    sync(viewToken, details.id).then((response) => {
      setSyncing(false)
      if (response.success) {
        console.log('Synced')
      } else {
        window.alert(response.data.message)
      }
    })
  }

  console.log('details', details)

  return (
    <div style={{ width: '100%' }}>
      {loading && <p>Loading...</p>}
      {!loading && details && (
        <div className="card">
          <div className="card-body">
            <div className="designs-header-row">
              <div>
                <h1>Poster Studio</h1>
                <p className="text-muted">Manage your poster designs.</p>
              </div>
              <div>
                {page !== 'create-design' && (
                  <button
                    className="btn btn-primary"
                    onClick={(e) => setPage('create-design')}
                  >
                    Create Design
                  </button>
                )}
                {page == '' && (
                  <button
                    className="btn btn-outline-primary"
                    onClick={onSyncNow}
                    disabled={syncing}
                    style={{ marginLeft: '10px' }}
                  >
                    {syncing ? 'Syncing...' : 'Sync Now'}
                  </button>
                )}
              </div>
            </div>
            <br />
            <div className="tab">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <button
                    className={`nav-link ${page == '' ? 'active' : ''}`}
                    data-toggle="tab"
                    onClick={(e) => setPage('')}
                  >
                    Designs
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${page == 'settings' ? 'active' : ''}`}
                    data-toggle="tab"
                    onClick={(e) => setPage('settings')}
                  >
                    Settings
                  </button>
                </li>
              </ul>
              <div>
                {page === '' ? (
                  <DesignsSection
                    details={details}
                    viewToken={viewToken}
                    setPage={setPage}
                    setEditDesignId={setEditDesignId}
                    selectedDesigns={selectedDesigns}
                    setSelectedDesigns={setSelectedDesigns}
                  />
                ) : page === 'settings' ? (
                  <SettingsSection
                    details={details}
                    viewToken={viewToken}
                    locations={locations}
                    setLocations={setLocations}
                  />
                ) : page === 'create-design' ? (
                  <CreateDesignPage
                    design={details}
                    setPage={setPage}
                    viewToken={viewToken}
                    setEditDesignId={setEditDesignId}
                  />
                ) : page === 'edit-design' ? (
                  <EditDesignPage
                    details={details}
                    setPage={setPage}
                    viewToken={viewToken}
                    designId={editDesignId}
                  />
                ) : (
                  <p>Unknown page</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PosterStudioAppPage
