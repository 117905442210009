import { useState, useEffect, useRef } from 'react'

import {
  getWooCommerceDetails,
  updateWooCommerceDetails,
} from 'utils/api/internal-apps'

import './OnboardingWooCommerceApp.css'

const hasValueBeenEdited = (install, values) => {
  if (!install) {
    return false
  }

  for (const key in values) {
    if (install[key] !== values[key]) {
      return true
    }
  }
  return false
}

const OnboardingWooCommerceApp = () => {
  const shopifyStoreRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [install, setInstall] = useState(null)
  const [blocker, setBlocker] = useState(null)

  const [storeUrl, setStoreUrl] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [role, setRole] = useState('supplier')

  const [saved, setSaved] = useState(false)
  const [saving, setSaving] = useState(false)

  const viewToken = new URLSearchParams(window.location.search).get('viewToken')

  const edited = hasValueBeenEdited(install, {
    storeUrl,
    apiKey,
    apiSecret,
    role,
  })

  const fetchDetails = () => {
    getWooCommerceDetails(viewToken, {}).then((response) => {
      setLoading(false)
      if (response.success) {
        setInstall(response.data.install)
        setBlocker(response.data.blocker)
        setLoading(false)
        setApiKey(response.data.install.apiKey || '')
        setApiSecret(response.data.install.apiSecret || '')
        setStoreUrl(response.data.install.storeUrl || '')
        setRole(response.data.install.role || 'supplier')
      } else {
        window.alert(response.data.message)
      }
    })
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  const onSave = (e) => {
    e.preventDefault()
    setSaving(true)
    updateWooCommerceDetails(viewToken, {
      apiKey,
      apiSecret,
      storeUrl,
      role,
    }).then((response) => {
      setSaving(false)
      if (response.success) {
        console.log('Settings saved successfully')
        fetchDetails()
        setSaved(true)

        setTimeout(() => {
          setSaved(false)
        }, 3000)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="onboarding-section-title">WooCommerce</h2>
              <p className="text-muted">
                Please follow the instructions below to setup the WooCommerce
                app.
              </p>
              {blocker && (
                <div className="alert alert-danger">
                  <div className="alert-message">{blocker}</div>
                </div>
              )}
              <br />
              <div className="shopify-section">
                <label className="shopify-item-title">WooCommerce Store</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Store URL"
                  autoComplete="off"
                  value={storeUrl}
                  onChange={(e) => setStoreUrl(e.target.value)}
                />
              </div>
              <div className="shopify-section">
                <label className="shopify-item-title">Consumer API Key</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Consumer API Key"
                  autoComplete="off"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                />
                <label className="shopify-item-title" style={{ marginTop: 5 }}>
                  Consumer Secret Key
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Consumer API Secret"
                  autoComplete="off"
                  value={apiSecret}
                  onChange={(e) => setApiSecret(e.target.value)}
                />
                <p>
                  <a
                    href="https://docs.hendricks.so/guides/2025/04/how-do-i-create-my-woocommerce-access-token"
                    target="_blank"
                  >
                    How do I create my WooCommerce access token?
                  </a>
                </p>
              </div>
              <div className="shopify-section">
                <label className="shopify-item-title">Role</label>
                <select
                  className="form-control"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="supplier">Supplier</option>
                </select>
              </div>
              <div className="shopify-section">
                {saved ? (
                  <button className="btn btn-success" disabled={true}>
                    Saved
                  </button>
                ) : edited ? (
                  <button
                    className="btn btn-primary"
                    onClick={onSave}
                    disabled={saving}
                  >
                    {saving ? 'Saving...' : 'Save'}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  // <option value="retailer">Retailer</option>
}

export default OnboardingWooCommerceApp
