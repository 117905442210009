import { useState, useEffect, useRef } from 'react'

import {
  getShopifyDetails,
  updateShopifyDetails,
  getShopifyOAuth1WithViewToken,
} from 'utils/api/internal-apps'

import './OnboardingShopifyApp.css'

const OnboardingShopifyApp = () => {
  const shopifyStoreRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [install, setInstall] = useState(null)
  const [blocker, setBlocker] = useState(null)
  const [accessType, setAccessType] = useState('app_store')
  const [shopifyStore, setShopifyStore] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [direction, setDirection] = useState('retailer')
  const [saving, setSaving] = useState(false)
  const [connecting, setConnecting] = useState(false)
  const [showCantEditStore, setShowCantEditStore] = useState(false)

  const viewToken = new URLSearchParams(window.location.search).get('viewToken')
  const page = new URLSearchParams(window.location.search).get('page')

  useEffect(() => {
    getShopifyDetails(viewToken, {}).then((response) => {
      setLoading(false)
      if (response.success) {
        setBlocker(response.data.blocker)
        setLoading(false)
        setAccessToken('')
        setShopifyStore(response.data.shopifyId || '')
        setDirection(response.data.direction || 'retailer')
        setAccessType(response.data.accessType || 'app_store')
        setInstall(response.data)
      } else {
        window.alert(response.data.message)
      }
    })
  }, [])

  const onUpdate = (e, body) => {
    e.preventDefault()
    setSaving(true)
    updateShopifyDetails(viewToken, body).then((response) => {
      setSaving(false)
      if (response.success) {
        console.log('Settings saved successfully')
        setInstall(response.data.details)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  const onConnect = (e) => {
    e.preventDefault()
    setConnecting(true)
    getShopifyOAuth1WithViewToken(viewToken, {
      redirect: page,
    }).then((response) => {
      setConnecting(false)
      console.log('response', response)
      if (response.success) {
        // Redirect full parent page
        const url = response.data.url
        window.top.location.href = url
      }
    })
  }

  const onClickShopifyStore = (e) => {
    e.preventDefault()
    console.log('onClickShopifyStore')
    if (install && install.connected && install.accessType === 'app_store') {
      setShowCantEditStore(true)
      return
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <h2 className="onboarding-section-title">Shopify Setup</h2>
      <p className="text-muted">
        Please follow the instructions below to setup the Shopify app.
      </p>
      {blocker && (
        <div className="alert alert-danger">
          <div className="alert-message">{blocker}</div>
        </div>
      )}
      <br />
      <div className="shopify-section">
        <label className="shopify-item-title">Shopify Store</label>
        <div className="myshopify-input-wrapper" onClick={onClickShopifyStore}>
          <input
            type="text"
            className="myshopify-input"
            autoComplete="off"
            placeholder="sample"
            value={shopifyStore}
            onChange={(e) => {
              setShopifyStore(e.target.value)
              onUpdate(e, { shopifyUrl: e.target.value })
            }}
            ref={shopifyStoreRef}
            disabled={
              install && install.connected && install.accessType === 'app_store'
            }
          />
          <label
            onClick={(e) => {
              e.preventDefault()
              shopifyStoreRef.current.focus()
            }}
          >
            .myshopify.com
          </label>
        </div>
        {showCantEditStore ? (
          <>
            <div
              className="badge badge-subtle-secondary"
              style={{ marginTop: 0 }}
            >
              You can not edit the store URL after connecting. You can
              disconnect in settings.
            </div>
          </>
        ) : (
          <p>
            <a
              href="https://docs.hendricks.so/guides/2025/04/where-do-i-find-my-shopify-store-url"
              target="_blank"
            >
              Where do I find my Shopify store URL?
            </a>
          </p>
        )}

        {saving && <div className="badge badge-subtle-success">Saving</div>}
      </div>

      <div className="shopify-section">
        <label className="shopify-item-title">Direction</label>
        <select
          className="form-control"
          value={direction}
          onChange={(e) => {
            setDirection(e.target.value)
            onUpdate(e, { direction: e.target.value })
          }}
        >
          <option value="retailer">Retailer</option>
          <option value="supplier">Supplier</option>
        </select>
        {saving && <div className="badge badge-subtle-success">Saving</div>}
      </div>

      {accessType === 'app_store' ? (
        <>
          <div className="shopify-section">
            <button
              className="btn btn-success"
              disabled={
                !install ||
                install.shopifyId === '' ||
                install.connected ||
                connecting
              }
              onClick={onConnect}
            >
              Connect Shopify Store
            </button>

            {install && install.shopifyId !== '' && install.connected ? (
              <>
                <br />
                <div
                  className="badge badge-subtle-success"
                  style={{ marginTop: 0 }}
                >
                  We are connected to your store!
                </div>
              </>
            ) : install && install.shopifyId !== '' ? (
              <p className="text-muted" style={{ marginTop: 10 }}>
                The button above will redirect you to the Shopify app store. You
                will be prompted to install the Hendricks app on your Shopify
                store. <br />
                <br />
                Otherwise, you can use a{' '}
                <button
                  className="custom-app-button"
                  onClick={(e) => {
                    setAccessType('custom')
                    onUpdate(e, { accessType: 'custom' })
                  }}
                >
                  custom app
                </button>
                .
              </p>
            ) : (
              <>
                <br />
                <div
                  className="badge badge-subtle-danger"
                  style={{ marginTop: 0 }}
                >
                  Please set your Shopify ID first
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="shopify-section">
            <label className="shopify-item-title">Access Token</label>
            <input
              type="text"
              className="form-control"
              placeholder="Custom Access Token"
              autoComplete="off"
              value={accessToken}
              onChange={(e) => {
                setAccessToken(e.target.value)
                onUpdate(e, { accessToken: e.target.value })
              }}
            />
            <p>
              <a
                href="https://docs.hendricks.so/guides/how-do-i-create-my-shopify-access-token"
                target="_blank"
              >
                How do I create my Shopify access token?
              </a>
            </p>
            {saving && <div className="badge badge-subtle-success">Saving</div>}
          </div>
          <div>
            <p className="text-muted">
              You can create a custom app to connect your Shopify data.
              <br />
              <br />
              Otherwise, you can use the{' '}
              <button
                className="custom-app-button"
                onClick={(e) => {
                  setAccessType('app_store')
                  onUpdate(e, { accessType: 'app_store' })
                }}
              >
                Shopify app store
              </button>
            </p>
          </div>
        </>
      )}
    </div>
  )
}

export default OnboardingShopifyApp
