import querystring from 'query-string'

import { getAuthToken, getCompanyId, clearCookies } from '../cookies'

export function getBaseURL() {
  if (window.location.host === 'localhost:5001') {
    return 'http://localhost:7000'
  }
  return 'https://api.hendricks.so'
}

export function post(route, headers, body) {
  const authToken = getAuthToken()
  if (authToken && authToken !== '') {
    headers['Authorization'] = `Bearer ${authToken}`
  }
  console.log('post :: authToken', authToken)

  const company = getCompanyId()
  if (company && company !== '') {
    headers['Company'] = company
  }

  headers['Content-Type'] = 'application/json'
  return fetch(`${getBaseURL()}${route}`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((res) => {
      console.log('res 1', res)
      console.log('res.status', res.status, res.status == 403)
      if (res.status == 403) {
        console.log('here 2')
        clearCookies()
        window.location = `${window.location.origin}/?reason=logged-out#2`
        return
      }
      return res
        .clone()
        .json()
        .then((data) => {
          console.log('yes')

          return {
            success: res.status == 200,
            statusCode: res.status,
            data,
          }
        })
    })
    .catch(() => {
      return {
        success: false,
        statusCode: 500,
        data: {
          message: 'Internal Server Error',
        },
      }
    })
}

export function patch(route, headers, body) {
  const authToken = getAuthToken()
  if (authToken && authToken !== '') {
    headers['Authorization'] = `Bearer ${authToken}`
  }

  const company = getCompanyId()
  if (company && company !== '') {
    headers['Company'] = company
  }

  headers['Content-Type'] = 'application/json'
  return fetch(`${getBaseURL()}${route}`, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((res) => {
      console.log('res 1', res)
      console.log('res.status', res.status, res.status == 403)
      if (res.status == 403) {
        console.log('here 2')
        clearCookies()
        window.location = `${window.location.origin}/?reason=logged-out#2`
        return
      }
      return res
        .clone()
        .json()
        .then((data) => {
          console.log('yes')

          return {
            success: res.status == 200,
            statusCode: res.status,
            data,
          }
        })
    })
    .catch(() => {
      return {
        success: false,
        statusCode: 500,
        data: {
          message: 'Internal Server Error',
        },
      }
    })
}

export function deleteAPI(route, headers, body) {
  const authToken = getAuthToken()
  if (authToken && authToken !== '') {
    headers['Authorization'] = `Bearer ${authToken}`
  }

  const company = getCompanyId()
  if (company && company !== '') {
    headers['Company'] = company
  }

  headers['Content-Type'] = 'application/json'
  return fetch(`${getBaseURL()}${route}`, {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((res) => {
      console.log('res 1', res)
      console.log('res.status', res.status, res.status == 403)
      if (res.status == 403) {
        console.log('here 2')
        clearCookies()
        window.location = `${window.location.origin}/?reason=logged-out#2`
        return
      }
      return res
        .clone()
        .json()
        .then((data) => {
          console.log('yes')

          return {
            success: res.status == 200,
            statusCode: res.status,
            data,
          }
        })
    })
    .catch(() => {
      return {
        success: false,
        statusCode: 500,
        data: {
          message: 'Internal Server Error',
        },
      }
    })
}

export function get(route, headers, queryParams) {
  const authToken = getAuthToken()
  if (authToken && authToken !== '') {
    headers['Authorization'] = `Bearer ${authToken}`
  }

  const company = getCompanyId()
  if (company && company !== '') {
    headers['Company'] = company
  }
  // Check for Accept header, if not present, set it to application/json
  if (!headers['Accept']) {
    headers['Accept'] = 'application/json'
  }
  let skipResponse = false
  if (headers['Accept'] === 'skip') {
    skipResponse = true
    headers['Accept'] = 'application/json' // Can't accept skip
  }
  headers['Content-Type'] = 'application/json'
  // console.log('queryParams', route, queryParams)
  let queryParamsStr = querystring.stringify(queryParams, {
    arrayFormat: 'comma',
  })
  if (queryParamsStr !== '') {
    // queryParamsStr = queryParamsStr.replaceAll('[object Object]', '&') // No idea why
    queryParamsStr = `?${queryParamsStr}`
  }
  // console.log('queryParamsStr', queryParamsStr)
  return fetch(`${getBaseURL()}${route}${queryParamsStr}`, {
    method: 'GET',
    headers: headers,
  })
    .then((res) => {
      // console.log('res', res)
      if (res.status == 403) {
        // console.log('here')
        clearCookies()
        return {
          success: res.status == 200,
          statusCode: res.status,
          data: {},
        }
      }
      if (
        headers['Accept'] === 'application/x-yaml' ||
        headers['Accept'] === 'application/yaml'
      ) {
        return res
          .clone()
          .text()
          .then((data) => {
            return {
              success: res.status == 200,
              statusCode: res.status,
              data,
            }
          })
      }
      if (skipResponse) {
        return {
          success: res.status == 200,
          statusCode: res.status,
          data: {},
        }
      }
      return res
        .clone()
        .json()
        .then((data) => {
          return {
            success: res.status == 200,
            statusCode: res.status,
            data,
          }
        })
    })
    .catch((e) => {
      console.log('error', e)
      return {
        success: false,
        statusCode: 500,
        data: {
          message: 'Internal Server Error',
        },
      }
    })
}
