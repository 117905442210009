import { get, post, patch, deleteAPI } from '../../utils/api/general'

export const getPosterStudioDetails = (viewToken, params) => {
  return get(
    `/internal-apps/poster-studio/details`,
    {
      'view-token': viewToken,
    },
    params
  )
}


export const updatePosterStudioDetails = (viewToken, data) => {
  return patch(
    `/internal-apps/poster-studio/details`,
    {
      'view-token': viewToken,
    },
    data,
  )
}

export const listDesigns = (viewToken, params) => {
  return get(
    `/internal-apps/poster-studio/designs`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const createDesign = (viewToken, data) => {
  return post(
    `/internal-apps/poster-studio/designs`,
    {
      'view-token': viewToken,
    },
    data
  )
}

export const getDesign = (viewToken, designId) => {
  return get(
    `/internal-apps/poster-studio/designs/${designId}`,
    {
      'view-token': viewToken,
    }
  )
}

export const updateDesign = (viewToken, designId, data) => {
  return patch(
    `/internal-apps/poster-studio/designs/${designId}`,
    {
      'view-token': viewToken,
    },
    data
  )
}
export const sync = (viewToken, installId) => {
  return post(
    `/internal-apps/poster-studio/sync?installIds=${installId}`,
    {
      'view-token': viewToken,
    }
  )
}