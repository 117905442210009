import React, { useState, useEffect } from 'react'
import {
  CheckCheck,
  ChevronLeft,
  ChevronRight,
  CircleCheck,
} from 'lucide-react'

import './EditDesign.css'
import { updateDesign, createDesign, getDesign } from './api'

export const EditDesignPage = ({ details, setPage, designId, viewToken }) => {
  const [savedDesign, setSavedDesign] = useState(null)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState('')
  const [sku, setSku] = useState('')
  const [description, setDescription] = useState('')
  const [variants, setVariants] = useState([])
  const [error, setError] = useState(null)

  const onSubmit = (e) => {
    e.preventDefault()

    setSaving(true)
    setError('')
    updateDesign(viewToken, designId, {
      title,
      sku,
      description,
      variants,
    }).then((response) => {
      setSaving(false)
      if (response.success) {
        // setPage('')
      } else {
        setError('There was an error saving the design.')
      }
    })
  }

  const onUpdateStatus = (e, newStatus) => {
    e.preventDefault()

    setSaving(true)
    setError('')
    updateDesign(viewToken, designId, {
      status: newStatus,
    }).then((response) => {
      setSaving(false)
      if (response.success) {
        // setPage('')
        setSavedDesign(response.data.design)
      } else {
        setError('There was an error saving the design.')
      }
    })
  }

  useEffect(() => {
    getDesign(viewToken, designId).then((response) => {
      setLoading(false)
      if (response.success) {
        const design = response.data.design
        setTitle(design.title || '')
        setSku(design.sku || '')
        setDescription(design.description || '')
        setVariants(design.variants || [])
        setSavedDesign(design)
      }
    })
  }, [])

  if (!details) {
    return (
      <EditDesignWrapper setPage={setPage} currentStep={2}>
        <div className="alert alert-danger" role="alert">
          <div className="alert-message">
            There was an error loading the product details.
          </div>
        </div>
      </EditDesignWrapper>
    )
  }

  if (loading) {
    return (
      <EditDesignWrapper setPage={setPage} currentStep={2}>
        <div className="edit-design-loading-row">
          <div className="spinner-border text-primary" role="status"></div>
          <p>Fetching design</p>
        </div>
      </EditDesignWrapper>
    )
  }

  return (
    <EditDesignWrapper setPage={setPage} currentStep={2}>
      <>
        <div className="upload-poster-header-row">
          <h2>Product Details</h2>
          <p className="text-muted">
            Provide product details for the poster you uploaded.
          </p>
        </div>
        <form onSubmit={onSubmit}>
          <div className="edit-design-form-inner-row">
            {error && error !== '' && (
              <div className="alert alert-danger" role="alert">
                <div className="alert-message">{error}</div>
              </div>
            )}
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>SKU {'(Variants may add a suffix)'}</label>
              <input
                type="text"
                className="form-control"
                value={sku}
                onChange={(e) => setSku(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>Variants</th>
                </tr>
              </thead>
              <tbody>
                {((details && details.variantTemplates) || []).map(
                  (variant) => (
                    <tr key={variant.id}>
                      <td style={{ width: 50 }}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={variant.id}
                          checked={variants.includes(variant.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setVariants([...variants, variant.id])
                            } else {
                              setVariants(
                                variants.filter((v) => v !== variant.id)
                              )
                            }
                          }}
                        />
                      </td>
                      <td>
                        <label
                          className="form-check-label"
                          htmlFor={variant.id}
                        >
                          {variant.title}
                        </label>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>

          <div className="next-btns-row">
            <button
              className="btn btn-primary"
              disabled={saving}
              style={{ marginRight: 10 }}
            >
              {saving ? <>Saving...</> : <>Save</>}
            </button>
            {savedDesign && savedDesign.status == 'active' ? (
              <button
                className="btn btn-outline-danger"
                onClick={(e) => onUpdateStatus(e, 'archived')}
                style={{ marginRight: 10 }}
              >
                {saving ? <>Moving...</> : <>Move to Archived</>}
              </button>
            ) : savedDesign && savedDesign.status == 'draft' ? (
              <button
                className="btn btn-success"
                onClick={(e) => onUpdateStatus(e, 'active')}
                style={{ marginRight: 10 }}
              >
                {saving ? <>Moving...</> : <>Move to Active</>}
              </button>
            ) : null}
          </div>
        </form>
      </>
    </EditDesignWrapper>
  )
}

const EditDesignStep = ({ step, title, description, currentStep }) => {
  return (
    <div className="edit-design-step">
      {currentStep > step ? (
        <CircleCheck className="edit-design-step-number" />
      ) : (
        <p
          className={`edit-design-step-number ${step !== currentStep ? 'edit-design-step-number-disabled' : ''}`}
        >
          {step}
        </p>
      )}
      <div>
        <p className="edit-design-step-title">{title}</p>
        <p>{description}</p>
      </div>
    </div>
  )
}

const EditDesignWrapper = ({ children, setPage, currentStep }) => {
  return (
    <div>
      <div className="back-to-designs-wrapper">
        <button className="nav-link" onClick={(e) => setPage('')}>
          <ChevronLeft /> Back to Designs
        </button>
      </div>
      <div className="edit-designs-wrapper">
        <div className="edit-design-steps-col">
          <EditDesignStep
            step={1}
            title="Upload Poster"
            description="Upload the poster file."
            currentStep={currentStep}
          />
          <EditDesignStep
            step={2}
            title="Product Details"
            description="Add produt details for the poster."
            currentStep={currentStep}
          />
        </div>
        <div className="edit-designs-content">{children}</div>
      </div>
    </div>
  )
}

const AIPromptForm = () => {
  const [aiPrompt, setAIPrompt] = useState('')
  const [results, setResults] = useState([])
  const [generating, setGenerating] = useState(false)

  return (
    <>
      <div className="form-group">
        <label>AI Prompt</label>
        <textarea
          className="form-control"
          value={aiPrompt}
          onChange={(e) => setAIPrompt(e.target.value)}
        ></textarea>
      </div>
      <br />
      <button className="btn btn-outline-primary" onClick={(e) => {}}>
        Generate
      </button>
      <br />
      <br />
      <p>
        <b>Outputs</b>
      </p>
      <div>
        <button>
          <img
            src="https://images.playground.com/416f0d44-97ad-4013-9826-30c1b62fb16d.jpeg"
            className="ai-prompt-results-img"
          />
          <CheckCheck />
        </button>
        <button>
          <img
            src="https://images.playground.com/416f0d44-97ad-4013-9826-30c1b62fb16d.jpeg"
            className="ai-prompt-results-img"
          />
        </button>
        <button>
          <img
            src="https://images.playground.com/416f0d44-97ad-4013-9826-30c1b62fb16d.jpeg"
            className="ai-prompt-results-img"
          />
        </button>
      </div>
    </>
  )
}

export const CreateDesignPage = ({
  viewToken,
  details,
  setPage,
  setEditDesignId,
}) => {
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [showInitPrompt, setShowInitPrompt] = useState(true)
  const [designId, setDesignId] = useState(null)

  const [toggleAI, setToggleAI] = useState(false)

  const onFileChange = (e) => {
    setFile(e.target.files[0])

    setUploaded(false)
    setUploading(true)
    setError('')

    const fileName = e.target.files[0].name
    const mimeType = e.target.files[0].type

    // Convert file to base64
    const reader = new FileReader()
    reader.onload = (e) => {
      const base64 = e.target.result

      // Trim prefix from base64
      const base64Trimmed = base64.split(',')[1]
      console.log(base64Trimmed)
      console.log('fileName', fileName)

      if (designId && designId !== '') {
        // Update the design
        updateDesign(viewToken, designId, {
          file: base64Trimmed,
          fileName,
          mimeType,
        }).then((response) => {
          console.log(response)
          setUploading(false)
          if (response.success) {
            setUploaded(true)
            setDesignId(response.data.design.id)
          }
        })
      } else {
        // Create the design
        createDesign(viewToken, {
          file: base64Trimmed,
          fileName,
          mimeType,
        }).then((response) => {
          console.log(response)
          setUploading(false)
          if (response.success) {
            setUploaded(true)
            setDesignId(response.data.design.id)
          }
        })
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }

  const onNext = (e) => {
    e.preventDefault()

    if (!designId || designId === '') {
      setError('Please upload a poster file.')
      return
    }

    setPage('edit-design')
    setEditDesignId(designId)
  }

  return (
    <EditDesignWrapper setPage={setPage} currentStep={1}>
      <>
        <div className="upload-poster-header-row">
          <h2>Upload your Poster</h2>
          <p className="text-muted">
            Upload the poster file for which you want to create a product.
          </p>
        </div>
        <form>
          <div className="edit-design-form-inner-row">
            {toggleAI ? (
              <AIPromptForm />
            ) : (
              <div className="form-group">
                <label>Main Poster File</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={onFileChange}
                />
                {/* <div>
                  <p className="text-muted edit-design-use-ai-text">
                    Otherwise,{' '}
                    <button
                      className="edit-design-use-ai-btn"
                      onClick={(e) => setToggleAI(!toggleAI)}
                    >
                      use AI
                    </button>{' '}
                    to generate a design.
                  </p>
                </div> */}
                {error && error !== '' && (
                  <div className="alert alert-danger" role="alert">
                    <div className="alert-message">{error}</div>
                  </div>
                )}
                {uploaded && (
                  <div className="edit-design-file-uploaded-row">
                    <CircleCheck /> File uploaded
                  </div>
                )}
                {uploading && (
                  <div className="edit-design-file-uploading-row">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                    <p>Uploading...</p>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="next-btns-row">
            <button className="btn btn-primary" onClick={onNext}>
              Next
            </button>
          </div>
        </form>
      </>
    </EditDesignWrapper>
  )
}

/*
    return (
      <div>
        <button className="nav-link" onClick={(e) => setPage('')}>
          <ChevronLeft /> Back to Designs
        </button>
        <br />
        <form>
          
          <div className="form-group"></div>
        </form>
      </div>
    )
      */
