import { useState, useEffect } from 'react'

import SettingsPage from '../SettingsPage'
import { getMe } from 'utils/api/users'

const ProductSyncingForm = ({ company, setCompany, loading }) => {
  const [saving, setSaving] = useState(false)

  const [productCreation, setProductCreation] = useState(
    company.productSyncing.productCreation || 'keep_in_sync'
  )
  const [deselectIfAllUnpriced, setDeselectIfAllUnpriced] = useState(
    company.productSyncing.deselectIfAllUnpriced || true
  )
  const [deselectIfAnyVariantDeleted, setDeselectIfAnyVariantDeleted] =
    useState(company.productSyncing.deselectIfAnyVariantDeleted || true)
  const [variantsSyncing, setVariantsSyncing] = useState(
    company.productSyncing.variantsSyncing || 'keep_in_sync'
  )
  const [imagesSyncing, setImagesSyncing] = useState(
    company.productSyncing.imagesSyncing || 'keep_in_sync'
  )

  const onSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label className="form-label">Product Syncing</label>
          <select
            className="form-control"
            value={productCreation}
            onChange={(e) => setProductCreation(e.target.value)}
          >
            <option value="keep_in_sync">Keep in sync</option>
            <option value="not_selected">Not selected</option>
          </select>
          <p className="text-muted" style={{ marginTop: 10 }}>
            <i>Keep In Sync</i>: As the supplier makes updates to their copy of
            the product, they automically sync to your store.
            <br />
            <i>Not Selected</i>: AS the supplier makes updates to their copy,
            they changes will sync through to all products that are not
            selected.
            {/* <i>Not Edited</i>: The product details are constantly updated if the
            retailer copy has not been edited. Once edited, only inventory will
            change.
            <br />
            <i>Only on Create</i>: The product details are only updated when the
            initial product is created. After that, only inventory will be
            updated. */}
          </p>
        </div>
        {productCreation !== company.productSyncing.productCreation && (
          <div className="form-group" style={{ paddingTop: 10 }}>
            <button
              type="button"
              className="btn btn-primary"
              disabled={saving || loading}
            >
              {saving ? 'Saving...' : 'Save'}
            </button>
          </div>
        )}
      </form>
    </>
  )
}

const VariantsSyncForm = ({ company, setCompany, loading }) => {
  const [saving, setSaving] = useState(false)

  const [syncVariants, setSyncVariants] = useState(
    company.productSyncing.variantsSyncing || 'keep_in_sync'
  )
  const [deselectIfAllUnpriced, setDeselectIfAllUnpriced] = useState(
    company.productSyncing.deselectIfAllUnpriced || true
  )
  const [deselectIfAnyVariantDeleted, setDeselectIfAnyVariantDeleted] =
    useState(company.productSyncing.deselectIfAnyVariantDeleted || true)

  const onSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label className="form-label">Variants Syncing</label>
          <select
            className="form-control"
            value={syncVariants}
            onChange={(e) => setSyncVariants(e.target.value)}
          >
            <option value="keep_in_sync">Keep in sync</option>
          </select>
          <p className="text-muted" style={{ marginTop: 10 }}>
            <i>Keep In Sync</i>: As the supplier makes updates to their copy of
            the variants (add & remove them), they automically add & remove them
            from your store.
          </p>
        </div>
        <div className="form-group" style={{ marginTop: 10 }}>
          <label className="form-label">Deselect If All Unpriced</label>
          <select
            className="form-control"
            value={company.productSyncing.deselectIfAllUnpriced}
            onChange={(e) => setDeselectIfAllUnpriced(e.target.value)}
          >
            <option value={true}>Yes</option>
            {/* <option value={false}>No</option> */}
          </select>
          <p className="text-muted" style={{ marginTop: 10 }}>
            If all variants for a product are unpriced, the entire product is
            deselect (moved to an inactive state).
          </p>
        </div>
        <div style={{ marginTop: 10 }}>
          <label className="form-label">Deselect If Any Variant Deleted</label>
          <select
            className="form-control mb-3"
            value={company.productSyncing.deselectIfAnyVariantDeleted}
            onChange={(e) => setDeselectIfAnyVariantDeleted(e.target.value)}
          >
            <option value={true}>Yes</option>
            {/* <option value={false}>No</option> */}
          </select>
          <p className="text-muted" style={{ marginTop: 10 }}>
            If a variant is deleted from the supplier, the entire product is
            deselected.
          </p>
        </div>
        {syncVariants !== company.productSyncing.variantsSyncing && (
          <div className="form-group" style={{ paddingTop: 10 }}>
            <button
              type="button"
              className="btn btn-primary"
              disabled={saving || loading}
            >
              {saving ? 'Saving...' : 'Save'}
            </button>
          </div>
        )}
      </form>
    </>
  )
}

const ImagesSyncForm = ({ company, setCompany, loading }) => {
  const [saving, setSaving] = useState(false)
  const [syncImages, setSyncImages] = useState(
    company.productSyncing.imagesSyncing || 'keep_in_sync'
  )

  const onSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label className="form-label">Images Syncing</label>
          <select
            className="form-control"
            value={syncImages}
            onChange={(e) => setSyncImages(e.target.value)}
          >
            <option value="keep_in_sync">Keep in sync</option>
          </select>
          <p className="text-muted" style={{ marginTop: 10 }}>
            <i>Keep In Sync</i>: As the supplier makes updates to their copy of
            the images, they automically sync to your store. This is all types
            of updates, including adding, removing, and updating positions.
          </p>
        </div>
        {syncImages !== company.productSyncing.imagesSyncing && (
          <div className="form-group" style={{ paddingTop: 10 }}>
            <button
              type="button"
              className="btn btn-primary"
              disabled={saving || loading}
            >
              {saving ? 'Saving...' : 'Save'}
            </button>
          </div>
        )}
      </form>
    </>
  )
}

const SettingsProductsPage = () => {
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState(null)

  const fetchCompany = () => {
    getMe({}).then((response) => {
      setLoading(false)
      if (response.success) {
        setCompany(response.data.company)
      }
    })
  }

  useEffect(() => {
    fetchCompany()
  }, [])

  if (!company) {
    return (
      <SettingsPage loading={loading}>
        <span />
      </SettingsPage>
    )
  }

  return (
    <SettingsPage
      loading={loading}
      developerMode={company.developerMode || false}
    >
      <h2 className="settings-section-title">Products</h2>
      <p className="text-muted" style={{ marginBottom: 10 }}>
        How your products sync and are managed.
      </p>

      <ProductSyncingForm
        company={company}
        setCompany={setCompany}
        loading={loading}
      />
      <br />
      <VariantsSyncForm
        company={company}
        setCompany={setCompany}
        loading={loading}
      />
      <br />
      <ImagesSyncForm
        company={company}
        setCompany={setCompany}
        loading={loading}
      />
    </SettingsPage>
  )
}

export default SettingsProductsPage
