import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import SharedProductsPage from '../Shared/SharedProductsPage'

import { createLocation, listLocations } from 'utils/api/locations'
import { Plus } from 'lucide-react'
import Modal from 'components/Modal/Modal'
import Textbox from 'components/Textbox/Textbox'
import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'

const CreateNewLocation = ({ toggle, refresh }) => {
  const [creating, setCreating] = useState(false)
  const [name, setName] = useState('')
  const [reference, setReference] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()

    setCreating(true)
    createLocation({
      name,
      reference,
      address1,
      address2,
      city,
      state,
      zip,
      country,
    }).then((response) => {
      if (response.success) {
        toggle()
        refresh()
      }
      setCreating(false)
    })
  }

  return (
    <Modal toggle={toggle} hidePanel>
      <div className="card">
        <div className="card-body">
          <h2 className="h3 mb-3">Create Location</h2>
          <form onSubmit={onSubmit}>
            <Textbox
              label="Name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Textbox
              label="Reference"
              placeholder="Reference"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
            />
            <Textbox
              label="Address 1"
              placeholder="Address 1"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
            />
            <Textbox
              label="Address 2"
              placeholder="Address 2"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
            <Textbox
              label="City"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <Textbox
              label="State"
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            <Textbox
              label="Zip"
              placeholder="Zip"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
            <Textbox
              label="Country"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
            <div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={creating}
              >
                {creating ? 'Creating...' : 'Create'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

const ListLocationsPage = () => {
  const navigate = useNavigate()
  const [showNewLocation, setShowNewLocation] = useState(false)
  const [search, setSearch] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const [selectedLocations, setSelectedLocations] = useState([])
  const [locations, setLocations] = useState([])
  const [initialLoading, setInitialLoading] = useState(true)

  const fetchLocations = () => {
    listLocations({
      search,
      page: 1,
    }).then((response) => {
      if (response.success) {
        setLocations(response.data.locations)
        setInitialLoading(false)
      }
    })
  }

  useEffect(() => {
    fetchLocations()
  }, [search])

  useEffect(() => {
    fetchLocations()
  }, [])

  const onView = (e, id) => {
    e.preventDefault()
    navigate(`/locations/${id}`)
  }

  return (
    <Page>
      <PageTitleRow>
        <h1 className="h3" style={{ paddingLeft: 12 }}>
          Locations
        </h1>
        <p className="text-muted">Manage your locations.</p>
      </PageTitleRow>
      <div className="card">
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-6 col-xl-4 mb-2 mb-md-0">
              <div className="input-group input-group-search">
                <input
                  type="text"
                  className="form-control"
                  id="datatables-products-search"
                  placeholder="Search locations..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button className="btn" type="button">
                  <i className="align-middle" data-lucide="search"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6 col-xl-8">
              <div className="text-sm-end">
                {/* <button type="button" className="btn btn-light btn-lg me-2">
                  <i data-lucide="download"></i> Export
                </button>*/}
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={(e) => setShowNewLocation(!showNewLocation)}
                >
                  <Plus /> New Location
                </button>
              </div>
            </div>
          </div>
          <table id="datatables-products" className="table w-100">
            <thead>
              <tr>
                {/* <th className="align-middle">
                  <div className="form-check fs-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="datatables-products-check-all"
                      value={selectAll}
                      onChange={() => setSelectAll(!selectAll)}
                    />
                    <label
                      className="form-check-label"
                      for="datatables-products-check-all"
                    ></label>
                  </div>
                </th> */}
                <th className="align-middle">Name</th>
                <th className="align-middle">Location</th>
                {/* <th className="align-middle text-end">Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {initialLoading ? (
                <div>
                  <p>Loading...</p>
                </div>
              ) : locations.length == 0 ? (
                <div>
                  <p>No locations found</p>
                </div>
              ) : (
                <>
                  {locations.map((location) => (
                    <tr>
                      {/* <td>
                        <div className="form-check fs-4">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="datatables-products-check-1"
                            value=""
                          />
                          <label
                            className="form-check-label"
                            for="datatables-products-check-1"
                          ></label>
                        </div>
                      </td> */}
                      <td>{location.name}</td>
                      <td>
                        {location.address1 && <p>{location.address1}</p>}
                        {location.address2 && <p>{location.address2}</p>}
                        {location.city && location.state && location.zip && (
                          <p>
                            {location.city} {location.state} {location.zip}
                          </p>
                        )}
                        <p>{location.country}</p>
                      </td>
                      {/* <td className="text-end">
                        <button
                          className="btn btn-primary"
                          onClick={(e) => onView(e, location.id)}
                        >
                          View
                        </button>
                      </td> */}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {showNewLocation && (
        <CreateNewLocation
          toggle={() => setShowNewLocation(!showNewLocation)}
          refresh={fetchLocations}
        />
      )}
    </Page>
  )
}

export default ListLocationsPage
