import { useState, useEffect, useRef } from 'react'

import { getPosterStudioDetails } from './api'

import './PosterStudioOnboardingPage.css'

const PosterStudioOnboardingPage = () => {
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState(null)

  const viewToken = new URLSearchParams(window.location.search).get('viewToken')

  useEffect(() => {
    getPosterStudioDetails(viewToken, {}).then((response) => {
      setLoading(false)
      if (response.success) {
        setDetails(response.data)
        setLoading(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }, [])

  return (
    <div>
      <h1>Dashboard</h1>
      {loading && <p>Loading...</p>}
      {!loading && details && (
        <div>
          <p>Details</p>
          <pre>{JSON.stringify(details, null, 2)}</pre>
        </div>
      )}
    </div>
  )
}

export default PosterStudioOnboardingPage
