import { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'
import Button from 'components/Button/Button'

import {
  getPricingById,
  listPricingEntries,
  searchProductsForPricing,
  searchVariantsForPricing,
  downloadPricingCSV,
  uploadPricingCSV,
  updateRetailPrice,
  updateBasePrice,
} from 'utils/api/pricing'

import './PricingViewPage.css'
import './AddEntryModal.css'
import Modal from 'components/Modal/Modal'
import Textbox from 'components/Textbox/Textbox'
import TextboxError from 'components/Textbox/TextboxError'
import DollarTextbox from 'components/Textbox/DollarTextbox'

const QuantityEntry = ({
  minQuantity,
  maxQuantity,
  entry,
  price,
  pricin,
  onUpdateChange,
}) => {
  const [priceValue, setPriceValue] = useState(
    convertToTwoDecimal(price.basePrice / 100) || ''
  )
  const [priceCurrency, setPriceCurrency] = useState(
    price.basePriceCurrency || 'USD'
  )
  const [saving, setSaving] = useState(false)

  const onChange = (value) => {
    setPriceValue(value && value !== '' ? value : '')

    if (value !== '') {
      onUpdateChange(entry.id, {
        basePrice: Math.ceil(value * 100),
        basePriceCurrency: priceCurrency,
        minQuantity,
      })
    }
  }

  return (
    <>
      <span className="quantity-entry">
        <p>
          {minQuantity} - {maxQuantity}
        </p>
        <div className="pricing-input-wrapper">
          <input
            type="text"
            value={priceValue}
            onChange={(e) => onChange(e.target.value)}
            className="form-control"
            style={{ maxWidth: 100 }}
          />
          <label className="form-label">{priceCurrency}</label>
        </div>
      </span>

      {saving && (
        <p className="saving-text">
          <div className="spinner-border" role="status"></div>
          Saving...
        </p>
      )}
    </>
  )
}

const convertToTwoDecimal = (value) => {
  const v = Math.ceil(value * 100) / 100
  if (v === Math.floor(v)) {
    return v + '.00'
  }
  if (v * 10 === Math.floor(v * 10)) {
    return v + '0'
  }
  return v
}

const PricingEntry = ({ pricing, entry, onUpdateChange, entryChanges }) => {
  const [retailPrice, setRetailPrice] = useState(
    entryChanges && entryChanges.retailPrice
      ? convertToTwoDecimal(entryChanges.retailPrice / 100)
      : entry.retailPrice
        ? convertToTwoDecimal(entry.retailPrice / 100)
        : ''
  )
  const [retailPriceCurrency, setRetailPriceCurrency] = useState(
    entry.retailPriceCurrency || 'USD'
  )
  const [saving, setSaving] = useState(false)
  // const [debounceRetailPrice] = useDebounce(retailPrice, 1000)

  // useEffect(() => {
  //   if (debounceRetailPrice === '') {
  //     console.log('Value is empty, not saving')
  //     return
  //   }
  //   // Convert debouced retail price to cents and int
  //   const debounceRetailPriceInt = Math.ceil(debounceRetailPrice)
  //   if (debounceRetailPriceInt === entry.retailPrice) {
  //     console.log('Value is the same, not saving')
  //     return
  //   }
  //   setSaving(true)
  //   console.log('Saving...')
  //   updateRetailPrice(pricing.id, {
  //     variantId: entry.variantId,
  //     retailPrice: debounceRetailPriceInt,
  //     retailPriceCurrency,
  //   }).then((response) => {
  //     setSaving(false)
  //     if (response.success) {
  //       console.log('Saved')
  //     } else {
  //       window.alert(response.data.message)
  //     }
  //   })
  // }, [debounceRetailPrice])

  const onRetailPriceChange = (value) => {
    console.log('onRetailPriceChange', value)
    setRetailPrice(value && value !== '' ? value : '')

    if (value !== '') {
      onUpdateChange(entry.id, {
        retailPrice: Math.ceil(value * 100),
        retailPriceCurrency,
      })
    }
  }

  return (
    <tr>
      <td>
        <div className="pricing-cell">
          <p>
            {entry.productTitle} / {entry.title}
          </p>
          <p className="pricing-variant-sku">
            {entry.sku !== '' && <span>{entry.sku} (SKU)</span>}
            {entry.barcode !== '' && (
              <span>
                {entry.barcode} {`(${entry.barcodeType})`}
              </span>
            )}
          </p>
        </div>
      </td>
      <td>
        <div className="pricing-cell">
          {Object.keys(entry.quantities).map((quantity, index) => {
            return (
              <QuantityEntry
                key={index}
                minQuantity={
                  index > 0 ? Object.keys(entry.quantities)[index - 1] : 0
                }
                maxQuantity={
                  Object.keys(entry.quantities).length - 1 === index
                    ? '∞'
                    : Object.keys(entry.quantities)[index + 1]
                }
                price={entry.quantities[quantity]}
                pricing={pricing}
                entry={entry}
                onUpdateChange={onUpdateChange}
              />
            )
          })}
        </div>
      </td>
      <td>
        <div className="pricing-cell">
          <div className="pricing-input-wrapper">
            <input
              type="text"
              value={retailPrice}
              onChange={(e) => onRetailPriceChange(e.target.value)}
              className="form-control"
              style={{ maxWidth: 100 }}
            />
            <label className="form-label">{retailPriceCurrency}</label>
          </div>
          {saving && (
            <p className="saving-text">
              <div className="spinner-border" role="status"></div>
              Saving...
            </p>
          )}
        </div>
      </td>
      <td>
        <div className="pricing-cell">
          <button className="pricing-delete-btn">
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </td>
    </tr>
  )
}

const applyChangeToPriceListEntry = (pricingId, id, body) => {
  return updateRetailPrice(pricingId, body).then((response) => {
    if (response.success) {
      return {
        applied: true,
        error: '',
      }
    } else {
      return {
        error: response.data.message,
        applied: false,
      }
    }
  })
}

const PricingEntries = ({ pricing }) => {
  const [entries, setEntries] = useState([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [changes, setChanges] = useState({}) // id: { ...request_body... }
  const [applyingChanges, setApplyingChanges] = useState(false)
  const [appliedChanges, setAppliedChanges] = useState(0)
  const [changeErrors, setChangeErrors] = useState([])

  const fetchEntries = () => {
    setLoading(true)
    listPricingEntries(pricing.id, { page }).then((response) => {
      if (response.success) {
        setEntries(response.data.entries || [])
        setLoading(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  const onUpdateChange = (id, body) => {
    // Get previous changes
    let entryChanges = {}
    if (changes[id]) {
      entryChanges = changes[id]
    }

    // Merge changes
    entryChanges = { ...entryChanges, ...body }

    if (Object.keys(entryChanges).length === 0) {
      // Remove entry from changes
      let newChanges = { ...changes }
      delete newChanges[id]
      setChanges(newChanges)
      return
    }
    setChanges({ ...changes, [id]: entryChanges })
  }

  const onApplyChanges = async (e) => {
    console.log('onApplyChanges...')
    e.preventDefault()

    setChangeErrors([])
    setApplyingChanges(true)
    for (const id in changes) {
      const body = changes[id]

      // Add variantId to body
      body.variantId = entries.find((entry) => entry.id === id).variantId

      const { applied, error } = await applyChangeToPriceListEntry(
        pricing.id,
        id,
        body
      )
      if (applied) {
        setAppliedChanges(appliedChanges + 1)
      } else {
        setChangeErrors([...changeErrors, error])
      }
    }

    setApplyingChanges(false)
    setTimeout(() => {
      setAppliedChanges(0)
      setChanges({})
    }, 3000)
  }

  useEffect(() => {
    fetchEntries()
  }, [])

  useEffect(() => {
    fetchEntries()
  }, [page])

  if (loading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    )
  }

  if (entries.length === 0) {
    return (
      <div>
        <p>No entries found</p>
      </div>
    )
  }

  console.log('changes', changes)
  return (
    <>
      {Object.keys(changes).length > 0 && (
        <div className="text-end w-100" style={{ paddingTop: 10 }}>
          {applyingChanges ? (
            <button className="btn btn-outline-primary" disabled>
              Applying Changes ({appliedChanges}/{Object.keys(changes).length})
            </button>
          ) : appliedChanges === Object.keys(changes).length ? (
            <button className="btn btn-success" disabled>
              Changes Applied
            </button>
          ) : (
            <button className="btn btn-primary" onClick={onApplyChanges}>
              Save {Object.keys(changes).length} Changes
            </button>
          )}
          {changeErrors.length > 0 && (
            <>
              {changeErrors.map((error) => (
                <TextboxError style={{ maxWidth: 150 }}>{error}</TextboxError>
              ))}
            </>
          )}
        </div>
      )}
      <table className="table w-100 dataTable dtr-inline">
        <thead>
          <tr>
            <th>Details</th>
            <th>Prices</th>
            <th>Retail Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {entries.map((entry) => (
            <PricingEntry
              entry={entry}
              pricing={pricing}
              onUpdateChange={onUpdateChange}
              entryChanges={changes[entry.id] || null}
            />
          ))}
        </tbody>
      </table>
    </>
  )
}

const AddEntryModal = ({ pricingId }) => {
  const [search, setSearch] = useState('')
  const [step, setStep] = useState(1)
  const [products, setProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [variants, setVariants] = useState([])
  const [selectedVariants, setSelectedVariants] = useState([])
  const [error, setError] = useState(null)
  const [basePrice, setBasePrice] = useState('')
  const [retailPrice, setRetailPrice] = useState('')

  const searchForProducts = () => {
    searchProductsForPricing(pricingId, { search }).then((response) => {
      if (response.success) {
        setProducts(response.data.products || [])
      } else {
        window.alert(response.data.message)
      }
    })
  }

  const searchForVariants = () => {
    searchVariantsForPricing(pricingId, { productIds: selectedProducts }).then(
      (response) => {
        if (response.success) {
          setVariants(response.data.variants || [])
        } else {
          window.alert(response.data.message)
        }
      }
    )
  }

  useEffect(() => {
    searchForProducts()
  }, [])

  useEffect(() => {
    searchForProducts()
  }, [search])

  const onNextAfterProducts = (e) => {
    if (selectedProducts.length === 0) {
      setError('Please select at least one product')
      return
    }
    setError(null)
    setStep(2)
    searchForVariants()
  }

  const onNextAfterVariants = (e) => {
    if (selectedVariants.length === 0) {
      setError('Please select at least one variant')
      return
    }
    setError(null)
    setStep(3)
  }

  if (step === 2) {
    return (
      <div className="add-pricing-entry-form">
        <h2 className="add-pricing-entry-form-title">Add Pricing Entry</h2>
        <p className="add-pricing-entry-form-instructions">
          Select specific variants to add to your price list.
        </p>
        {variants.length > 0 ? (
          <div>
            {variants.map((variant) => (
              <div
                key={variant.id}
                className="add-pricing-entry-form-variant-item"
              >
                <div className="add-pricing-entry-form-variant-item-checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedVariants([
                          ...selectedVariants,
                          variant.variantId,
                        ])
                      } else {
                        setSelectedVariants(
                          selectedVariants.filter(
                            (vId) => vId !== variant.variantId
                          )
                        )
                      }
                    }}
                    checked={selectedVariants.find(
                      (id) => id === variant.variantId
                    )}
                    id={variant.variantId}
                  />
                </div>
                <div className="add-pricing-entry-form-variant-item-details">
                  <h3 htmlFor={variant.variantId}>
                    {variant.productTitle} / {variant.title}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No variants found</p>
        )}
        <div className="add-pricing-entry-form-next-btn-wrapper">
          {error && error !== '' && <TextboxError>{error}</TextboxError>}
          <Button onClick={(e) => onNextAfterVariants(e)}>Next</Button>
        </div>
      </div>
    )
  }
  if (step === 3) {
    return (
      <div className="add-pricing-entry-form">
        <h2 className="add-pricing-entry-form-title">Add Pricing Entry</h2>
        <div>
          <Textbox
            placeholder="Base Price"
            value={basePrice}
            onChange={(e) => setBasePrice(e.target.value)}
            helpText="The price you will charge the retailer for the product."
          />
          <Textbox
            placeholder="Retail Price"
            value={retailPrice}
            onChange={(e) => setRetailPrice(e.target.value)}
            helpText="The price the retailer may charge the customer for the product."
          />
        </div>
      </div>
    )
  }
  console.log('selectedProducts', selectedProducts)
  return (
    <div className="add-pricing-entry-form">
      <h2 className="add-pricing-entry-form-title">Add Pricing Entry</h2>
      <p className="add-pricing-entry-form-instructions">
        Search for specific products to add to your pricing.
      </p>
      <Textbox
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div>
        {products.length > 0 ? (
          <div>
            {products.map((product) => (
              <div
                key={product.id}
                className="add-pricing-entry-form-product-item"
              >
                <div className="add-pricing-entry-form-product-item-checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedProducts([
                          ...selectedProducts,
                          product.productId,
                        ])
                      } else {
                        setSelectedProducts(
                          selectedProducts.filter(
                            (pId) => pId !== product.productId
                          )
                        )
                      }
                    }}
                    checked={selectedProducts.find(
                      (id) => id === product.productId
                    )}
                  />
                </div>
                <div className="add-pricing-entry-form-product-item-image">
                  <img src={product.imageUrl} alt={product.title} />
                </div>
                <div className="add-pricing-entry-form-product-item-details">
                  <h3>{product.title}</h3>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No products found</p>
        )}
      </div>
      <div className="add-pricing-entry-form-next-btn-wrapper">
        {error && error !== '' && <TextboxError>{error}</TextboxError>}
        <Button onClick={(e) => onNextAfterProducts(e)}>Next</Button>
      </div>
    </div>
  )
}

const PricingDetails = () => {
  return <div></div>
}

const PricingViewPage = () => {
  let { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [pricing, setPricing] = useState(null)
  const [tab, setTab] = useState('entries')
  const [showAddEntryModal, setShowAddEntryModal] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [uploading, setUploading] = useState(false)

  const uploadCSVRef = useRef(null)
  const [pricingCSVUpload, setPricingCSVUpload] = useState(null)

  let navigate = useNavigate()

  useEffect(() => {
    getPricingById(id).then((response) => {
      if (response.success) {
        setPricing(response.data.priceList)
        setLoading(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }, [])

  const onToggleAddEntryModal = () => {
    setShowAddEntryModal(!showAddEntryModal)
  }

  const onDownloadCSV = (empty) => {
    setDownloading(true)
    downloadPricingCSV({
      type: empty ? 'empty' : 'all',
    }).then((response) => {
      setDownloading(false)
      if (response.success) {
        let csv = response.data.csv
        // Replace all \\n with \n
        csv = csv.replace(/\\n/g, '\n')
        const blob = `data:text/csv;charset=utf-8,${csv}`

        var encodedUri = encodeURI(blob)
        var link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'pricing.csv')
        document.body.appendChild(link) // Required for FF
        link.click()
      } else {
        window.alert(response.data.message)
      }
    })
  }

  const onUploadCSV = (e) => {
    e.preventDefault()

    uploadCSVRef.current.click()
  }

  const onUploadCSVChange = (e) => {
    setUploading(true)
    const file = e.target.files[0]
    setPricingCSVUpload(file)

    // Convert to base64
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64Text = reader.result.split(',')[1]
      console.log('Beginning to upload CSV')
      uploadPricingCSV(pricing.id, {
        content: base64Text,
      }).then((response) => {
        console.log('response', response)
        setUploading(false)
        setPricingCSVUpload(null)
        if (response.success) {
          window.alert('CSV uploaded successfully')
        } else {
          window.alert(response.data.message)
        }
      })
    }
  }

  if (loading) {
    return (
      <Page>
        <p>Loading...</p>
      </Page>
    )
  }

  return (
    <Page>
      <PageTitleRow>
        <div className="col-12">
          <div className="page-title-details-col">
            <h1>{pricing.name}</h1>
            <button className="back-btn" onClick={(e) => navigate('/pricing')}>
              <i className="bi bi-chevron-left"></i>
              Back to Pricing
            </button>
          </div>
          <div className="page-title-actions-col">
            {pricing.isOwner && (
              <>
                {/* <Button className="page-title-action-item" onClick={(e) => onToggleAddEntryModal()}>Add</Button> */}
                <div className="page-title-action-item">
                  <div className="page-title-action-upload-wrapper">
                    <Button onClick={onUploadCSV} loading={uploading}>
                      Upload CSV
                    </Button>
                    <input
                      type="file"
                      id="pricing-csv-upload"
                      ref={uploadCSVRef}
                      style={{ display: 'none' }}
                      onChange={onUploadCSVChange}
                    />
                    {downloading ? (
                      <button className="page-title-download-csv">
                        Downloading - May take a few minutes...
                      </button>
                    ) : (
                      <>
                        <button
                          className="page-title-download-csv"
                          onClick={(e) => onDownloadCSV(false)}
                        >
                          Download CSV with All Variants
                        </button>
                        <button
                          className="page-title-download-csv"
                          onClick={(e) => onDownloadCSV(true)}
                        >
                          Download CSV Empty Template
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="tabs-row">
          <button
            className={`${tab == 'entries' ? 'active-tab' : ''}`}
            onClick={(e) => setTab('entries')}
          >
            Entries
          </button>
          <button
            className={`${tab == 'details' ? 'active-tab' : ''}`}
            onClick={(e) => setTab('details')}
          >
            Details
          </button>
        </div>
      </PageTitleRow>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {tab === 'entries' ? (
                <PricingEntries pricing={pricing} />
              ) : (
                <PricingDetails />
              )}
            </div>
          </div>
        </div>
      </div>
      {showAddEntryModal && (
        <Modal toggle={onToggleAddEntryModal}>
          <AddEntryModal pricingId={pricing.id} />
        </Modal>
      )}
    </Page>
  )
}

export default PricingViewPage
