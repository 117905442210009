import React, { useState } from 'react'

import { usePopper } from 'react-popper'

import './SharedOnboardingPage.css'

const getSteps = () => {
  const originalList = [
    {
      label: 'Use Cases',
      link: '/onboarding/',
      popperText: 'We want to know more about your goals.',
    },
    {
      label: 'Plans',
      link: '/onboarding/plans',
      popperText: 'Find the right plan.',
    },
    {
      label: 'Apps',
      link: '/onboarding/apps',
      popperText: 'Lets connect existing systems.',
    },
    {
      label: 'App Setup',
      link: '/onboarding/apps',
      popperText: 'Lets setup those apps.',
    },
    {
      label: 'Invoicing',
      link: '/onboarding/invoicing',
      popperText: 'Setup invoicing your suppliers.',
    },
    {
      label: 'Partners',
      link: '/onboarding/partners',
      popperText: 'Add your partners.',
    },
    {
      label: 'Discovery',
      link: '/onboarding/discovery',
      popperText: 'Configure discovering products.',
    },
    {
      label: 'Syncing',
      link: '/onboarding/syncing',
      popperText: 'See syncing statuses.',
    },
    {
      label: 'Pricing',
      link: '/onboarding/pricing',
      popperText: 'Price your products.',
    },
    {
      label: 'Done',
      link: '/onboarding/completed',
      popperText: "You're done!",
    },
  ]

  if (isWhiteLabelledDomain()) {
    // White labeled domain and remove 1st step (use case), 2nd (plans), 6th (partners), 7th step (discovery)
    const whiteLabelledList = originalList.filter(
      (step, index) => index !== 0 && index !== 1 && index !== 5 && index !== 6
    )
    console.log('whiteLabelledList', whiteLabelledList)
    console.log('originalList', originalList)
    return whiteLabelledList
  }
  return originalList
}

export const isWhiteLabelledDomain = () => {
  const hostName = window.location.hostname
  return (
    hostName !== 'localhost' &&
    hostName !== 'app.hendricks.so' &&
    hostName !== 'hendricks.so'
  )
}

const determineCurrentStep = () => {
  const path = window.location.pathname
  const steps = getSteps()
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].link === path) {
      return i + 1
    }
  }
  return 1
}

const LeftMenuLink = ({ label, link, popperText, stepNumber, currentStep }) => {
  // const [refElement, setRefElement] = useState(null)
  // const [popperElement, setPopperElement] = useState(null)
  // const { styles, attributes } = usePopper(refElement, popperElement, {
  //   modifiers: [],
  // })
  // ref={setRefElement}
  return (
    <a
      href={link}
      className={`step-link ${stepNumber == currentStep ? 'current-step' : stepNumber < currentStep ? 'completed-step' : ''}`}
    >
      {stepNumber}
      {'. '}
      {label}
      {/* <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        Popper element
      </div> */}
    </a>
  )
}

const SharedOnboardingPage = ({ title, children }) => {
  const currentStep = determineCurrentStep()
  return (
    <div className="container">
      <div className="row onboarding-title-row">
        <div className="col-2"></div>
        <div className="col-8">
          <h1>{title}</h1>
          <p>
            Step {currentStep} of {getSteps().length || 10}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-2">
          <p>Steps</p>
          {getSteps().map((step, index) => (
            <LeftMenuLink
              key={index}
              stepNumber={index + 1}
              label={step.label}
              link={step.link}
              popperText={step.popperText}
              currentStep={currentStep}
            />
          ))}
          {/* <LeftMenuLink
            stepNumber={1}
            label="1. Use Cases"
            link="/onboarding/"
            popperText="We want to know more about your goals."
            currentStep={step}
          />
          <LeftMenuLink
            stepNumber={2}
            label="2. Plans"
            link="/onboarding/plans"
            popperText="Find the right plan."
            currentStep={step}
          />
          <LeftMenuLink
            stepNumber={3}
            label="3. Apps"
            link="/onboarding/apps"
            popperText="Lets connect existing systems."
            currentStep={step}
          />
          <LeftMenuLink
            stepNumber={4}
            label="4. App Setup"
            link="/onboarding/apps"
            popperText="Lets setup those apps."
            currentStep={step}
          />
          <LeftMenuLink
            stepNumber={5}
            label="5. Invoicing"
            link="/onboarding/invoicing"
            popperText="Setup invoicing your suppliers."
            currentStep={step}
          />
          <LeftMenuLink
            stepNumber={6}
            label="6. Partners"
            link="/onboarding/partners"
            popperText="Add your partners."
            currentStep={step}
          />
          <LeftMenuLink
            stepNumber={7}
            label="7. Discovery"
            link="/onboarding/discovery"
            popperText="Configure discovering products."
            currentStep={step}
          />
          <LeftMenuLink
            stepNumber={8}
            label="8. Syncing"
            link="/onboarding/syncing"
            popperText="See syncing statuses."
            currentStep={step}
          />
          <LeftMenuLink
            stepNumber={9}
            label="9. Pricing"
            link="/onboarding/pricing"
            popperText="Price your products."
            currentStep={step}
          />
          <LeftMenuLink
            stepNumber={10}
            label="10. Done"
            link="/onboarding/completed"
            popperText="You're done!"
            currentStep={step}
          /> */}
        </div>
        <div className="col-md-6">{children}</div>
      </div>
    </div>
  )
}

export default SharedOnboardingPage
